import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Row, Col, Modal, message } from 'antd';
import Button from 'atoms/Button'

// Theme
import styled from "@emotion/styled";

// Common
import COLORS from 'common/Colors';
import { NON_EMPTY_REGEX, NON_EMPTY_SINGLE_NUMBER, STATUS_CODE } from 'common/Constants';
import { CALL_API } from 'common/API';
import { DEVICE } from 'common/Device';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 2rem 3rem 1rem 3rem;
  }
  .select-role-title {
    margin-bottom: 0rem;
  }
  .select-role-sub-title {
    color: #8a8a8a;
    padding-bottom: 2rem;
  }
  @media ${DEVICE.tablet}  {
    width: 100% !important;
    .ant-modal-body {
      padding: 1rem !important;
    }
  }
`

const ModalHeaderRow = styled.h3`
  padding-bottom: ${props => (props.isConfirmationModal === true ? `0rem` : `1rem`)};
  margin-bottom: ${props => (props.isConfirmationModal === true ? `0.25rem` : `0.5rem`)};
  font-weight: bold;
`


const StyleSubmitButton = styled(Button)`
    margin-top: 1.5rem;
    font-weight: bold;
    background: ${COLORS.PRIMARY};
    &:hover {
        background: ${COLORS.PRIMARY};   
    }
    width: 130px;
`

const StyledErrorDiv = styled.div`
  color: #ff7a7a;
  font-weight: bold;
  font-size: small;
`

const ChangePasswordModal = ({ openResetPasswordModal, toggleResetPasswordModal }) => {
  const [resetPasswordForm] = Form.useForm();
  const [resetLoading, setResetLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userDetails, setUserDetails] = useState({
    user_id: '',
    email_id: ''
  });

  const onResetPasswordFinish = async (values) => {
    setResetLoading(true);
    const { code } = await CALL_API('change-password', 'post', {
      username: values.username,
      newpassword: values.newpassword,
    });
    if(code === STATUS_CODE.PASSWORD_UPDATED){
        message.success('Password Updated Successfully')
        toggleResetPasswordModal(false)
    }
    else if (code === STATUS_CODE.SAME_PASSWORD) {
        setErrorMessage('Please enter new password other than previous one.'); 
    }
    else if (code === STATUS_CODE.INVALID_PAYLOAD) {
        setErrorMessage('Something is wrong with data.');
    }
   
    setResetLoading(false);
    resetPasswordForm.resetFields();
  };

  const onResetPasswordFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  useEffect(() => {
    if (openResetPasswordModal) {
      resetPasswordForm.resetFields();
    }
  }, [openResetPasswordModal])



  return (
    <>
      <StyledModal
        centered
        visible={openResetPasswordModal}
        footer={false}
        keyboard={false}
        maskClosable={false}
        width='45%'
        onCancel={() => toggleResetPasswordModal(false)}
      >
        <ModalHeaderRow>Change Password</ModalHeaderRow>
        <form autocomplete="off" list="autocompleteOff">
          <Form
            form={resetPasswordForm}
            layout="vertical"
            name="basic"
            initialValues={{
              username: '',
              newpassword: '',
              confirmpassword: '',
            }}
            onFinish={onResetPasswordFinish}
            onFinishFailed={onResetPasswordFinishFailed}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter username!',
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder="User Name"
              />
            </Form.Item>

            <Form.Item
              name="newpassword"
              required
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter new password!',
                },
              ]}
            >
              <Input.Password autoComplete="new-password" placeholder="New Password" />
            </Form.Item>

            <Form.Item
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter new password again!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newpassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" />
            </Form.Item>

            <Row>
              <Col span={24} className='align-center'>
                {
                  errorMessage && (
                    <StyledErrorDiv className='align-center'>
                      {errorMessage}
                    </StyledErrorDiv>
                  )
                }
                <Form.Item>
                  <StyleSubmitButton loading={resetLoading} htmlType="submit" type='primary'>Submit</StyleSubmitButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </form>
      </StyledModal>

    </>
  );
}

export default ChangePasswordModal;
