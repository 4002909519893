import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Modal } from "antd";
import Button from "atoms/Button";

// Theme
import styled from "@emotion/styled";

// Common
import COLORS from "common/Colors";
import {
  NON_EMPTY_REGEX,
  NON_EMPTY_SINGLE_NUMBER,
  STATUS_CODE,
} from "common/Constants";
import { CALL_API } from "common/API";
import { DEVICE } from "common/Device";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 2rem 3rem 1rem 3rem;
  }
  .select-role-title {
    margin-bottom: 0rem;
  }
  .select-role-sub-title {
    color: #8a8a8a;
    padding-bottom: 2rem;
  }
  @media ${DEVICE.tablet} {
    width: 100% !important;
    .ant-modal-body {
      padding: 1rem !important;
    }
  }
`;

const ModalHeaderRow = styled.h3`
  padding-bottom: ${(props) =>
    props.isConfirmationModal === true ? `0rem` : `1rem`};
  margin-bottom: ${(props) =>
    props.isConfirmationModal === true ? `0.25rem` : `0.5rem`};
  font-weight: bold;
`;

const ModalHeaderSubRow = styled.h4`
  padding-bottom: ${(props) =>
    props.isConfirmationModal === true ? `0rem` : `1rem`};
  margin-bottom: 0rem;
  color: ${COLORS.SECONDARY_BLACK};
`;

const StyleSubmitButton = styled(Button)`
  margin-top: 1.5rem;
  font-weight: bold;
  background: ${COLORS.PRIMARY};
  &:hover {
    background: ${COLORS.PRIMARY};
  }
  width: 130px;
`;

const StyledCodeTextBox = styled(Col)`
    padding: 0px 5px;
    display: flex;
`

const StyledCodeBoxContainer = styled(Row)`
  padding: 1rem 0rem;
  .ant-input {
    height: 40px;
    text-align: center;
  }
`;

const StyledErrorDiv = styled.div`
  color: #ff7a7a;
  font-weight: bold;
  font-size: small;
`;

const ResetPasswordModal = ({
  openResetPasswordModal,
  toggleResetPasswordModal,
  username
}) => {
  const [resetPasswordForm] = Form.useForm();
  const [confirmationCodeForm] = Form.useForm();
  const [confirmationCodeModal, setConfirmationCodeModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userDetails, setUserDetails] = useState({
    user_id: "",
    email_id: "",
  });

  const onResetPasswordFinish = async (values) => {
    setResetLoading(true);
    const { code, email_id, user_id } = await CALL_API(
      "reset-password",
      "post",
      {
        username: values.username,
        newpassword: values.newpassword,
      }
    );
    setResetLoading(false);
    if (code === STATUS_CODE.SUCCESS) {
      setUserDetails({
        email_id,
        user_id,
      });
      toggleResetPasswordModal();
      setConfirmationCodeModal(true);
      resetPasswordForm.resetFields();
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      setErrorMessage("Something is wrong with data.");
    } else if (code === STATUS_CODE.SAME_PASSWORD) {
      setErrorMessage("Please enter new password other than previous one.");
    }
  };

  const onResetPasswordFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onConfirmagionCodeFinish = async (values) => {
    setErrorMessage("");
    setConfirmLoading(true);
    const { user_id } = userDetails;
    const { code } = await CALL_API("validate-reset-password-code", "post", {
      user_id,
      code: codeArr.join(''),
    });
    setConfirmLoading(false);
    if (code === STATUS_CODE.PASSWORD_UPDATED) {
      Modal.success({
        content: "Password updated successfully.",
        onOk() {
          setConfirmationCodeModal(false);
        },
      });
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      setErrorMessage("Something is wrong with data.");
    }
  };

  const onConfirmagionCodeFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [codeArr, setCodeArr] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    const updatedCode = [...codeArr];
    updatedCode[index] = value;
    setCodeArr(updatedCode);

    if (value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (event) => {
    const pastedCode = event.clipboardData.getData('text');
    const updatedCode = pastedCode
      .split('')
      .slice(0, 6)
      .map((value, index) => {
        if (inputRefs.current[index]) {
          confirmationCodeForm.setFieldsValue({[`input_${index}`] : value})
        }
        return value;
      });
    setCodeArr(updatedCode);
    event.preventDefault();
    if (updatedCode.length > 0 && updatedCode.length <= 6) {
      const lastInputIndex = updatedCode.length - 1;
      inputRefs.current[lastInputIndex].value = updatedCode[lastInputIndex];
      inputRefs.current[lastInputIndex].focus();
    }
  };


  useEffect(() => {
      resetPasswordForm.resetFields();
      confirmationCodeForm.resetFields();
  }, [openResetPasswordModal]);


  return (
    <>
      <StyledModal
        centered
        visible={openResetPasswordModal}
        footer={false}
        keyboard={false}
        maskClosable={false}
        width="45%"
        onCancel={() => toggleResetPasswordModal(false)}
      >
        <ModalHeaderRow>Forgot Password</ModalHeaderRow>
        <form autocomplete="off" list="autocompleteOff">
          <Form
            form={resetPasswordForm}
            layout="vertical"
            name="basic"
            initialValues={{
              username: username ? username : "",
              newpassword: "",
              confirmpassword: "",
            }}
            onFinish={onResetPasswordFinish}
            onFinishFailed={onResetPasswordFinishFailed}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Please enter username!",
                },
              ]}
            >
              <Input
                // autoComplete="off"
                placeholder="User Name"
              />
            </Form.Item>

            <Form.Item
              name="newpassword"
              required
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Please enter new password!",
                },
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                placeholder="New Password"
              />
            </Form.Item>

            <Form.Item
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Please enter new password again!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newpassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" />
            </Form.Item>

            <Row>
              <Col span={24} className="align-center">
                {errorMessage && (
                  <StyledErrorDiv className="align-center">
                    {errorMessage}
                  </StyledErrorDiv>
                )}
                <Form.Item>
                  <StyleSubmitButton
                    loading={resetLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    Reset
                  </StyleSubmitButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </form>
      </StyledModal>

      <StyledModal
        centered
        visible={confirmationCodeModal}
        footer={false}
        keyboard={false}
        maskClosable={false}
        width="45%"
        onCancel={() => setConfirmationCodeModal(false)}
      >
        <ModalHeaderRow isConfirmationModal={true}>
          Confirmation Code
        </ModalHeaderRow>
        <ModalHeaderSubRow isConfirmationModal={true}>
          Enter the 6 digit confirmation code we sent on
        </ModalHeaderSubRow>
        <ModalHeaderSubRow>{userDetails.email_id}</ModalHeaderSubRow>
        <Form
          form={confirmationCodeForm}
          layout="vertical"
          name="basic"
          onFinish={onConfirmagionCodeFinish}
          onFinishFailed={onConfirmagionCodeFinishFailed}
        >
          <StyledCodeBoxContainer>
            <Col md={{ offset: 2, span: 20 }}>
              <Row>
              {codeArr.map((value, index) => (
                
                <StyledCodeTextBox span={4}>
                   <Form.Item name={`input_${index}`} rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}>
                    <Input
                      size="large"
                      key={index}
                      type="text"
                      value={value}
                      onChange={(event) => handleChange(index, event.target.value)}
                      onPaste={(event) => handlePaste(event, index)}
                      maxLength={1}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      
                    />
                 
                 </Form.Item>
                </StyledCodeTextBox>     
                   ))}
              </Row>
            </Col>
          </StyledCodeBoxContainer>

          <Row>
            <Col span={24} className="align-center">
              <Form.Item>
                <StyleSubmitButton
                  loading={confirmLoading}
                  htmlType="submit"
                  type="primary"
                >
                  Confirm
                </StyleSubmitButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledModal>
    </>
  );
};

export default ResetPasswordModal;
