import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId
} from 'redux/selectors';

import { CALL_API } from 'common/API';
import { size } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 20px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function AttendanceReportOverview({
  userRoleId,
  selectedDepartmentId
}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [reportData, setReportData] = useState([
    {
      type: 'On time employees',
      value: 0,
    },
    {
      type: 'Late employees',
      value: 0,
    }
  ]);
  const config = {
    appendPadding: 10,
    data: reportData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    interactions: [
      {
        type: 'element-active',
      },
    ],
    color: ({ type }) => {
      if (type === 'On time employees') {
        return COLORS.EMPLOYEES.ONTIME;
      }
      return COLORS.EMPLOYEES.LATE;
    },
    height: 170
  };

  useEffect(() => {
    if ((userRoleId !== 1 && selectedDepartmentId !== 0) || (userRoleId !== 6 && selectedDepartmentId !== 0) || userRoleId === 1 || userRoleId === 6) {
      getReportData();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getReportData();
    }
  }, [selectedDepartmentId]);

  const getReportData = async () => {
    toggleReportLoader(true);
    const {
      late,
      onTime
    } = await CALL_API('attendance-service', 'post', {
      method: 'todayOverallLateAndTimelyLoginCount',
      parameters: {
        departmentId: userRoleId !== 1 ? selectedDepartmentId : ''
      },
    });
    let dupeReportData = Array.from(reportData);
    dupeReportData[0].value = size(onTime);
    dupeReportData[1].value = size(late);
    setReportData(dupeReportData);
    toggleReportLoader(false);
  }

  return (
    <>
      <Spin spinning={displayReportLoader}>
        <StyledTitle>ATTENDANCE REPORT OVERVIEW</StyledTitle>
        <StyledChartContainer>
          <div className='name-title'>
            {(userRoleId === 1 || userRoleId === 6) ? `Today's all employee ` : `Department's today's `}
            attendance report
          </div>
          <Pie {...config} />
        </StyledChartContainer>
      </Spin>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps, null)(AttendanceReportOverview);
