import React from 'react';
import {
  EyeFilled,
  EditFilled
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, Row, Spin, Table, Form, DatePicker} from 'antd';
import { useEffect, useState } from 'react';
import { includes, concat, get, ceil, find } from 'lodash'
// Redux
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  toggleProcessingModal
} from 'redux/actions';
import {
  getSelectedDepartmentId, getUserRoleId, getUserInfo, getUserList
} from 'redux/selectors';
import ViewTaskInfo from 'organisms/ViewTaskInfo';
import EditTask from "organisms/EditTask";
import { getMySqlDate } from "utils/Date";

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
    cursor: pointer;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    min-width: 200px;
    white-space: normal;
  }
  .highlighted {
    font-weight: 600;
    text-decoration: underline;
  }
  .remaining {
    color: orange;
    font-weight: 600;
  }
  .overdue {
    color: red;
    font-weight: 600;
  }
`;

const StyledSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;

function Task({ userRoleId, selectedDepartmentId, userInfo, userList }) {
  const [viewTaskModal, toggleViewTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);
  const [editingTask, toggleEditingTask] = useState(false);
  const [filterTaskList, setFilterTaskList] = useState([]);
  const [form] = Form.useForm();

  const calculateDateDifference = (endDate) => {
    const currentDate = moment().startOf("day");
    const parsedEndDate = moment(endDate).startOf("day");
    const difference = parsedEndDate.diff(currentDate, "days");
    return ceil(difference);
  };

  const viewTask = (taskObject, isViewOnly = false) => {
    toggleEditingTask(true);
    setTaskDetails({
      ...taskObject,
      isViewOnly,
    });
  };

  const columns = [
    {
      title: "Task No",
      dataIndex: "task_id",
      key: "task_id",
      width: "10%",
      onCell: (record) => ({
        onClick: () => viewTaskInfo(record),
      }),
      render: (text) => <span className="highlighted">{`TRTP-${text}`}</span>,
    },
    {
      title: "Task Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      className: "normal-column",
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Created Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Due Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status_id",
      key: "status_id",
      width: "10%",
      render: (status_id) => {
        return status_id == 1 ? "New Task" : "Completed";
      },
    },
    {
      title: "Remaining days",
      dataIndex: "remaining_day",
      key: "remaining_day",
      width: "10%",
      render: (_, record) => {
        const daysLeft = calculateDateDifference(record.end_date);
        return (
          <span className="remaining">
            {record.status_id != 3 ? (
              <span className="remaining">
                {daysLeft >= 0 ? daysLeft : "-"}
              </span>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      title: "Over Due days",
      dataIndex: "overdue_day",
      key: "overdue_day",
      width: "10%",
      render: (_, record) => {
        const daysLeft = calculateDateDifference(record.end_date);
        return (
          <span className="overdue">
            {record.status_id != 3 ? (
              <span className="overdue">
                {daysLeft <= 0 ? Math.abs(daysLeft) : "-"}
              </span>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      title: "Assigned By",
      dataIndex: "assigned_by_user_id",
      key: "assigned_by_user_id",
      width: "15%",
      render: (assigned_by_user_id) => {
        const foundObj = find(userList, { user_id: assigned_by_user_id });
        return foundObj ? foundObj.name : "-";
      },
    },
  ];

  columns.push({
    title: "Action",
    dataIndex: "address",
    key: "address",
    width: "10%",
    align: "center",
    render: (_, record) => (
      <>
        <EyeFilled
          className="action-button"
          onClick={() => viewTaskInfo(record)}
        />
        <EditFilled
          className="action-button"
          onClick={() => viewTask(record, true)}
        />
      </>
    ),
  });

  useEffect(() => {
    getTaskList();
  }, []);

  useEffect(() => {
    getTaskList();
  }, [selectedDepartmentId, userRoleId]);

  const getTaskList = async () => {
    setDisplayTaskLoader(true);
    const { code, tasks } = await CALL_API("task-list", "post", {
      department_id:
        userRoleId === 4
          ? [get(userInfo, "staff_department_id", "")]
          : selectedDepartmentId,
      selected_role_id: userRoleId,
    });
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      let recordList = [];
      tasks.map((list) => {
        // Note: First parameter is needed
        Object.entries(list).forEach(([key, value]) => {
          recordList = concat(recordList, value);
        });
      });
      recordList.reverse();
      const result = recordList.filter((val) => {
        return (
          val?.user_id === get(userInfo, "employer_id", "") &&
          val?.assigned_by_user_id !== get(userInfo, "employer_id", "")
        );
      });
      setTaskList(result.reverse());
      setFilterTaskList(result.reverse());
    }
    setDisplayTaskLoader(false);
  };

  const getFilterTask = () => {
    const filteredTasks = taskList.filter((task) => {
      const startDate = form.getFieldValue("start_date");
      const endDate = form.getFieldValue("end_date");

      if (startDate && endDate) {
        return (
          getMySqlDate(task.end_date) >= getMySqlDate(startDate) &&
          getMySqlDate(task.end_date) <= getMySqlDate(endDate)
        );
      }

      return false;
    });

    setFilterTaskList(filteredTasks);
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < moment(date) || form.getFieldValue("end_date") == "") {
      form.setFieldsValue({ end_date: date });
    }
    getFilterTask();
  };

  const viewTaskInfo = (taskObject) => {
    toggleViewTask(true);
    setTaskDetails({
      ...taskObject,
    });
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <>
      <Row className="page-title">
        <Col span={12} className="page-title-head">
          Tasks
        </Col>
        <Col span={24}>
          <StyledSearchForm
            form={form}
            name="approval_form"
            layout="inline"
            initialValues={{
              start_date: "",
              end_date: "",
            }}
          >
            <Form.Item
              name="start_date"
              rules={[
                () => ({
                  // note: First parameter is needed
                  validator(rule, value) {
                    const date = moment(value);
                    return date.isValid()
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder="Due Start date"
                onChange={validateDateRange}
                allowClear={false}
              />
            </Form.Item>

            <Form.Item
              name="end_date"
              rules={[
                () => ({
                  // note: First parameter is needed
                  validator(rule, value) {
                    const date = moment(value);
                    return date.isValid()
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder="Due End date"
                onChange={validateDateRange}
                allowClear={false}
              />
            </Form.Item>
          </StyledSearchForm>
        </Col>
      </Row>
      <Spin spinning={displayTaskLoader}>
        <StyledTable
          dataSource={filterTaskList}
          columns={columns}
          // Note: First parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="task_id"
          pagination={filterTaskList.length > 20 ? paginationOptions : false}
        />
      </Spin>
      <ViewTaskInfo
        isModalVisible={viewTaskModal}
        closeModal={() => toggleViewTask(false)}
        taskDetails={taskDetails}
      />
      <EditTask
        isModalVisible={editingTask}
        closeModal={() => toggleEditingTask(false)}
        getTaskList={getTaskList}
        title="Edit Task"
        taskDetails={taskDetails}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Task);
