import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Spin, message, Modal } from 'antd';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
} from 'redux/selectors';
import styled from 'styled-components';
import Dropzone from 'molecules/Dropzone';
import COLORS from 'common/Colors';
import { get, map, includes } from 'lodash';
import Document from 'molecules/Document';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import { DEVICE } from 'common/Device';

const StyledDocumentPageTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 0px;
`;

const StyleDocumentRow = styled(Row)`
  padding-top: 20px;
`;

const StyledDocumentDiv = styled.div`
  border: solid 1px ${COLORS.GREY};
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  .nodata{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      letter-spacing: 2px;
      font-weight: bold;
      color:${COLORS.GREY};
    }
  }
  ${({ height }) => height && `height: ${height}px; min-height: ${height}px; max-height: ${height}px; overflow: auto;`};
  @media ${DEVICE.tablet}  {
    margin-top: 20px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const StyledDocumentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

function MyDocuments({
  userRoleId,
}) {
  const [documentList, setDocumentList] = useState([]);
  const [height, setHeight] = useState(200);
  const [fileName, setFileName] = useState('');
  const [isUploading, toggleUploading] = useState(false);
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = async () => {
    toggleLoadingDocuments(true);
    const { list } = await CALL_API('document-list', 'post', {
      method: 'document-list'
    });
    setDocumentList(list);
    toggleLoadingDocuments(false);
  }

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.onload = async function (e) {
        toggleUploading(true);
        console.log("type---->",  get(file, 'type', ''))
        if ( includes(["jpg", "png", 'jpeg', 'svg', 'xls', 'xlsx', 'pdf', 'csv', 'doc', 'docx', 'txt', 'ppt', 'avi', 'zip', 'mov'], get(file, 'type', '').split('/')[1])){
          const { code } = await CALL_API('upload-document', 'post', {
            name: get(file, 'name', ''),
            type: get(file, 'type', ''),
            content: e.target.result,
          });
          if (code === STATUS_CODE.SUCCESS) {
            message.success('Document uploaded');
            toggleUploading(false);
            getDocumentList();
          } else {
            message.error('Something went wrong!! Please try again.');
          }
        }
        else message.error('Invalid file format');
        toggleUploading(false);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const deleteDocument = async (id) =>{
    const response = await CALL_API('upload-document', 'delete', {
      documentId : id,
      status_id: 0
    });
    message.success("Document has been Deleted")
    getDocumentList();
  }
 
  useEffect(() => {
    setHeight(window.innerHeight - document.querySelector('.ant-layout-header').getBoundingClientRect().height - 100);

    window.uploadDocumentFromApp = async (base64, name, MIMEtype) => {
      console.log('uploadDocumentFromApp');
      toggleUploading(true);
      const { code } = await CALL_API('upload-document', 'post', {
        name,
        type: MIMEtype,
        content: base64,
      });
      if (code === STATUS_CODE.SUCCESS) {
        message.success('Document uploaded');
        toggleUploading(false);
        getDocumentList();
      } else {
        message.error('Something went wrong!! Please try again.');
      }
    }
  }, []);

  const uploadDocument = () => {
    if (navigator.userAgent === 'application') {
      window.Print.postMessage("upload-document");
    }
  }

  return (
    <>
      <StyledDocumentPageTitle>
        My Documents
      </StyledDocumentPageTitle>
      <StyleDocumentRow gutter={20}>
        <Col md={12} sm={24} xs={24} onClick={uploadDocument}>
          <Spin spinning={isUploading}>
            <Dropzone
              onDrop={onDrop}
              accept={"image/*,.png,.jpeg"}
              fileName={fileName}
            />
          </Spin>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Spin spinning={isLoadingDocuments}>
            <StyledDocumentDiv height={height}>
            {documentList?.length > 0 ?
              <Row gutter={24}>
                {
                  map(documentList, ({ document_id, document_name, created_at }, index) => (
                    <Col md={12} lg={8} sm={12} xs={12} key={index}>
                      <StyledDocumentContainer >
                        <Document
                          id={document_id}
                          name={document_name}
                          staffName=''
                          uploadTime={created_at}
                          deleteDocument={deleteDocument}
                        // setIsModalOpen={setIsModalOpen}
                        />
                      </StyledDocumentContainer>
                    </Col>
                  ))
                }
              </Row>
                 : 
               <div className='nodata'>
                 <p>No Data</p>
               </div>}
            </StyledDocumentDiv>
          </Spin>
        </Col>
      </StyleDocumentRow>
      {/* <Modal title="Basic Modal" open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal> */}
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(MyDocuments);
