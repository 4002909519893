import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styled from "@emotion/styled";

// Lodash
import {
    startCase
} from 'lodash';

// Redux
import { connect } from 'react-redux';
import { getUserName, getTime, getAMPM, getLongDay, getUserRoleId } from 'redux/selectors';

// Common
import COLORS from 'common/Colors';

// Components
import Button from 'atoms/Button'
import LeaveForm from 'organisms/LeaveForm';
import { DEVICE } from 'common/Device';

const StyleSubmitButton = styled(Button)`
    margin-top: 1.5rem;
    font-weight: bold;
    background: ${COLORS.PRIMARY};
    &:hover {
        background: ${COLORS.PRIMARY};   
    }
    width: 130px;
    position: absolute;
    bottom: 0;
    right: 0;
`
const StyledRow = styled(Row)`
    margin-top: -0.9rem;
    margin-left : 2rem;
    padding-top: 5px;
    .align-left{
        display:flex;
        flex-direction: column;
         @media ${DEVICE.tablet}  {
            margin-top : 3rem;
       }
    }
   
`

const StyledUserName = styled.div`
    font-size: 1.5rem;
    color: ${COLORS.PRIMARY};
    font-weight: bold;
    position: absolute;
    bottom: 0;
    overflow-x: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    @media ${DEVICE.tablet}  {
        display: contents;
    }
`;

const StyledTime = styled.span`
    font-size: 1.2rem;
    color: ${COLORS.PRIMARY};
    font-weight: bold;
`;

const StyledAMPM = styled.span`
    color: ${COLORS.PRIMARY};
    font-size: 12px;
    padding-left: 10px;
    font-weight: 600;
    white-space: nowrap;
    @media ${DEVICE.tablet}  {
        font-size: 12px;
        margin-left: -10px;
    }
`;

const StyledDate = styled.div`
    color: ${COLORS.PRIMARY};
    font-size: 12px;
    margin-top: -45px;
    white-space: nowrap;
`;

const StyledApplyLeaveCol = styled(Col)`
    @media ${DEVICE.tablet}  {
        padding-top: 50px;
    }
`;

function DashboardTop({
    userName,
    systemTime,
    ampm,
    longDay,
    userRoleId
}) {
  const [ displayLeaveModal, toggleLeaveModal ] = useState(false);
  return (
      <>
        <StyledRow>
            {/* <Col md={8} xs={24} sm={24}> */}
              {/* <StyledUserName>Hello {startCase(userName)}!</StyledUserName> */}
            {/* </Col> */}

            <Col md={8} xs={24} sm={24} className='align-left'>
                <div>
                    <StyledTime>
                        {systemTime}
                    </StyledTime>
                    <StyledAMPM>
                        {ampm} {"(Sydney Time Zone)"}
                    </StyledAMPM>
                </div>
                <StyledDate>
                    {longDay}
                </StyledDate>
            </Col>

            {/* <StyledApplyLeaveCol md={8} xs={24} sm={24} className='align-right'> */}
                {/* <div>
                    {
                        userRoleId === 4 && <StyleSubmitButton type='primary' onClick={() => toggleLeaveModal(true)}>Apply for Leave</StyleSubmitButton>
                    }
                </div> */}
            {/* </StyledApplyLeaveCol> */}
        </StyledRow>
        <LeaveForm 
          isModalVisible={displayLeaveModal}
          closeModal={() => toggleLeaveModal(false)}
          leaveDetails={{}}
        />
      </>
  );
}

const mapStateToProps = state => ({
    userName: getUserName(state),
    systemTime: getTime(state),
    ampm: getAMPM(state),
    longDay: getLongDay(state),
    userRoleId: getUserRoleId(state)
});

export default connect(mapStateToProps, null)(DashboardTop);
