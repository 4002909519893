import React, {useState, useEffect} from 'react';

// Components
import DashboardTop from 'molecules/DashboardTop';
import CheckInCheckout from 'molecules/CheckInCheckout';
import StaffAnnualLeave from 'molecules/StaffAnnualLeave';
import NonStaffDashboard from 'molecules/NonStaffDashboard';
import UserLeaveReport from 'molecules/UserLeaveReport';
import UserCheckinReport from 'molecules/UserCheckinReport';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId
} from 'redux/selectors';
import UserMonthlyReport from 'molecules/UserMonthlyReport';
import WeeklyShiftDetails from 'molecules/WeeklyShiftDetails';

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function Dashboard({ userRoleId }) {
  return (
    <>
      {/* <DashboardTop /> */}
      {
        userRoleId === 4 && (
          <>
            <StyledRow gutter={16}>
              <Col md={24} sm={24} xs={24} xl={13}>
                <Col md={24} sm={24} xs={24} xl={24}>
                  <CheckInCheckout />
                </Col>
                <Col md={24} sm={24} xs={24} xl={24}>
                   <UserLeaveReport />
                </Col>
                <Col md={24} sm={24} xs={24} xl={24}>
                  <UserMonthlyReport/>
                </Col>
              </Col>
              <Col md={24} sm={24} xs={24} xl={11}>
                <Col md={24} sm={24} xs={24} xl={24}>
                  <WeeklyShiftDetails/>
                </Col>
                <Col md={24} sm={24} xs={24} xl={24}>
                  <UserCheckinReport />
                </Col>   
              </Col>
            </StyledRow>
            {/* <StaffAnnualLeave /> */}
          </>
        )
      }
        {
          userRoleId !== 4 && <NonStaffDashboard />
        }
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state)
});

export default connect(mapStateToProps, null)(Dashboard);
