import React, { useEffect, useState } from "react";
import TeamCard from "molecules/TeamCard";
import { Row, Col, Input, Select, Button, Modal } from "antd";
import { DEVICE } from "common/Device";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getUnreadChatMessageList,
  getUserInfo,
  getDepartmentList,
} from "redux/selectors";
import { toggleSuccessModal } from "redux/actions";
import styled from "@emotion/styled";
import {
  map,
  concat,
  filter,
  get,
  size,
  uniqBy,
  includes,
  find,
  result,
} from "lodash";
import Icon from "atoms/Icon";
import COLORS from "common/Colors";
import EventBus from "utils/EventBus";

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .selectbox {
    width: 100%;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledBroadcastIcon = styled(Icon)`
  height: 40px !important;
  padding: 0px !important;
  cursor: pointer;
`;

const StyledFooterDiv = styled.div`
  text-align: right;
  padding-top: 10px;
  .ant-btn {
    font-weight: bold !important;
    color: ${COLORS.PRIMARY};
    padding-right: 0px !important;
  }
  .ant-btn:hover {
    border: none;
  }
`;

function Team({
  userList,
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  updateSuccessModal,
  unreadChatMessageList,
  userInfo,
}) {
  const [isBroadcastModalVisible, toggleBroadcastModalVisible] = useState(false);
  const [sendingMessageLoader, ToggleSendingMessageLoader] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const { TextArea } = Input;

  useEffect(() => {
    if (userRoleId === 1 || userRoleId === 6) {
      setSelectedDepartment("");
    } else {
      setSelectedDepartment(selectedDepartmentId);
    }
  }, [selectedDepartmentId]);

  const sendMessage = () => {
    const staffIds = map(getStaffList(), "user_id");
    if (size(staffIds) && broadcastMessage) {
      ToggleSendingMessageLoader(true);
      EventBus.$dispatch("sendMessage", {
        action: "BROADCAST",
        payload: {
          message: broadcastMessage,
          receiverUserIds: map(getStaffList(), "user_id"),
        },
      });
      setTimeout(() => {
        toggleBroadcastModalVisible(false);
        ToggleSendingMessageLoader(false);
      }, 1000);
      setTimeout(() => {
        updateSuccessModal({
          visibility: true,
          message: `Message has been broadcasted.`,
        });
        setBroadcastMessage("");
      }, 1000);
    }
  };

  const StaffList = () => {
    if (userRoleId === 1 || userRoleId === 6) {
      return concat(
        selectedDepartment !== ""
          ? filter(userList, { department_id: selectedDepartment })
          : userList,
        filter(userList, (user) => user?.role_id === 1 || user?.role_id === 6)
      );
    } else if (userRoleId === 4) {
      // return concat(
      //   filter(
      //     userList,
      //     (val) =>
      //       get(userInfo, "staff_department_id", "") == val?.department_id
      //   ),
      //   filter(userList, (user) => user?.role_id === 1 || user?.role_id === 6)
      // );
      return filter( userList, (val) => get(userInfo, "staff_department_id", "") == val?.department_id)
    } else {
      return concat(
        selectedDepartment !== []
          ? filter(userList, (val) =>
              includes(selectedDepartment, val?.department_id)
            )
          : userList,
        filter(userList, (user) => user?.role_id === 1 || user?.role_id === 6)
      );
    }
  };

  const getStaffList = () => {
    const allStaffList = userList;

    var staffList;
    if (userRoleId === 1 || userRoleId === 6) {
      staffList =
        selectedDepartment !== ""
          ? filter(allStaffList, { department_id: selectedDepartment })
          : allStaffList;
      return uniqBy(
        employeeNameFilter !== ""
          ? filter(staffList, (staff) => {
              return staff?.user_id === employeeNameFilter;
            })
          : staffList,
        "user_id"
      );
    } else {
      if (userRoleId === 4) {
        staffList = filter(
          allStaffList,
          (val) =>
            get(userInfo, "staff_department_id", "") == val?.department_id
        );
      } else {
        staffList =
          selectedDepartment !== []
            ? filter(allStaffList, (val) =>
                includes(selectedDepartment, val?.department_id)
              )
            : allStaffList;
      }
      if (userRoleId !== 4) {
        staffList = concat(
          staffList,
          filter(userList, (user) => user?.role_id === 1 || user?.role_id === 6)
        );
      }

      return uniqBy(
        employeeNameFilter !== ""
          ? filter(staffList, (staff) => {
              return staff?.user_id === employeeNameFilter;
            })
          : staffList,
        "user_id"
      );
    }
  };
  const STAFF_LIST = uniqBy(StaffList(), "user_id")?.map((val) => {
    return { user_id: val?.user_id, name: val?.name };
  });

  return (
    <>
      <StyledTitle className="page-title-head">List of Employees</StyledTitle>
      <StyledFilterRow gutter={16}>
        <Col md={6} sm={24} xs={24}>
          <Select
            className="selectbox"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? "")
                .toLowerCase()
                .localeCompare((optionB?.name ?? "").toLowerCase())
            }
            placeholder="Select Employee"
            options={concat(
              {
                user_id: "",
                name: "All Employee",
              },
              STAFF_LIST
            )}
            fieldNames={{
              label: "name",
              value: "user_id",
            }}
            onChange={(value) => setEmployeeNameFilter(value)}
          />
        </Col>
        <Col md={5} sm={24} xs={24}>
          {(userRoleId === 1 || userRoleId === 6) && (
            <Select
              className="selectbox"
              placeholder="Select Department"
              options={concat(
                {
                  department_id: "",
                  department_name: "All Departments",
                },
                allDepartmentList
              )}
              fieldNames={{
                label: "department_name",
                value: "department_id",
              }}
              onChange={(id) => setSelectedDepartment(id)}
            />
          )}
        </Col>
        <Col md={13} sm={24} xs={24} className="align-right">
          {userRoleId !== 4 && (
            <StyledBroadcastIcon
              name="broadcast"
              onClick={() => toggleBroadcastModalVisible(true)}
            />
          )}
        </Col>
      </StyledFilterRow>
      <Row>
        {map(
          getStaffList(),
          ({ user_id, name, email_id, department_id, profile_url, designation, role_name }, index) => (
            <StyledCol key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
              <TeamCard
                id={user_id}
                name={name}
                email_id={email_id}
                hasUnreadMessage={
                  includes(unreadChatMessageList, user_id) > 0 ? true : false
                }
                dept_name={result(
                  find(allDepartmentList, (val) => {
                    return val.department_id === department_id;
                  }),
                  "department_name"
                )}
                profile={profile_url}
                designation= {designation}
                role_name={role_name}
              />
            </StyledCol>
          )
        )}
      </Row>
      <Modal
        centered
        visible={isBroadcastModalVisible}
        closable={false}
        footer={false}
        keyboard={false}
        title="Broadcast Message"
        onCancel={() => toggleBroadcastModalVisible(false)}
      >
        <TextArea
          rows={4}
          placeholder="Type a message."
          maxLength={500}
          value={broadcastMessage}
          onChange={(e) => setBroadcastMessage(e.target.value)}
        />
        <StyledFooterDiv>
          <Button
            type="link"
            onClick={sendMessage}
            loading={sendingMessageLoader}
          >
            Send to all
          </Button>
        </StyledFooterDiv>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  departmentList: getDepartmentList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Team);
