import React, { useEffect, useState, useRef } from 'react';
import { Line  } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size, unionBy, find, sumBy } from 'lodash';
import { DatePicker, Space } from 'antd';
import { dateInDetail, getSydneyDateTime } from 'utils/Date';
import { CALL_API } from 'common/API';
import moment from 'moment';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  .header{
    display:flex;
    justify-content: space-between;
  }
`;

const StyledChartContainer = styled.div`
  height:380px;
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    display:flex;
    justify-content:space-between;
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function OverTimeAnalysis({}) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
  const [ reportData, setReportData ] = useState([
    {
        month:'Jan',
        Hours: 0
    },
    {
        month:'Feb',
        Hours: 0
    },
    {
        month:'Mar',
        Hours: 0
    },
    {
        month:'Apr',
        Hours: 0
    },
    {
        month:'May',
        Hours: 0
    },
    {
        month:'Jun',
        Hours: 0
    },
    {
        month:'Jul',
        Hours: 0
    },
    {
        month:'Aug',
        Hours: 0
    },
    {
        month:'Sep',
        Hours: 0
    },
    {
        month:'Oct',
        Hours: 0
    },
    {
        month:'Nov',
        Hours: 0
    },
    {
        month:'Dec',
        Hours: 0
    },
  ]);
  const config = {
    data:reportData,
    xField: 'month',
    yField: 'Hours',
    height: 300,
    label:false,
    point: {
      size: 5,
      // shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    yAxis: {
        // max: isNaN(max(map(reportData, 'Hours'))) ? 100 : max(map(reportData, 'Hours')) + 15,

    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
      
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    animationBegin: 0,
    animationDuration: 2000,
    animationEasing: 'ease-in-out'
  }

  const defaultYear = moment().year();

  useEffect(() => { 
    getData(moment(`${defaultYear}`, 'YYYY'));
  }, []);

  const [yearSelected, setYearSelected] = useState(null)
  const [total, setTotal] = useState(0)
  const containerRef = useRef(null);
 
  const getData = async (year) =>{
    const yearString = year.format('YYYY');
    setYearSelected(yearString)
    toggleReportLoader(true)
    const { ovetimeAnlysisData } = await CALL_API('admin-dashboard', 'post', {
      method: 'overTimeAnlysis',
      parameters: {
        year: yearString,
      },
    });
    const mappedData = ovetimeAnlysisData.map(item => ({
      month: item.Month.substring(0, 3),
      Hours: parseInt(item.hours.split(':')[0])
    }));
    setReportData(prevData => {
      const mergedData = unionBy(prevData, mappedData, 'month');
      mergedData.forEach(item => {
        const Hours2 = (find(mappedData, { 'month': item.month }) || {}).Hours || 0;
        item.Hours = Hours2;
      });
      return mergedData;
    });
    setTotal(sumBy(reportData, 'Hours'));
    toggleReportLoader(false)

  }

  return (
    <>      
      <StyledTitle>
          <div className='header' ref={containerRef} >
              OverTime Analysis
              <DatePicker.YearPicker getPopupContainer={() => containerRef.current}  defaultValue={moment(`${defaultYear}`, 'YYYY')} clearIcon={null} onChange={getData} />
          </div>    
      </StyledTitle>
      <StyledChartContainer>
      <div className='name-title'>
          <p style={{color:'blue'}}>Year : {yearSelected} </p>
          <p>Total hrs of OT for the year <span style={{color:'blue'}}>{total} hrs</span></p>
      </div>
        <Spin spinning={displayReportLoader}>
          <Line 
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}


export default OverTimeAnalysis