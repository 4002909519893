import React from "react";
import {
  Modal,
  Row,
  Col,
  Popover,
  Button,
  Divider,
} from "antd";

// Redux
import { connect } from "react-redux";
import {
  getLeaveCategoryList,
  getUserInfo,
  getUserRoleId,
} from "redux/selectors";
import styled from "@emotion/styled";
import { dateInDetail } from "utils/Date";
import ViewMore from "organisms/ViewMore";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 5px;
`;

const StyledAmmendmentTitle = styled.div`
  padding: 10px 0px 7px 0px;
`;

const StyledLeaveDetailsPopover = styled.div`
  padding: 5px 1rem 1rem;
  .ant-col {
    padding: 3px;
  }
  .ant-col:nth-child(1) {
    font-weight: bold;
  }
  .sub_title{
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }
`;

function ViewLeaveInfo({
  isModalVisible,
  closeModal,
  leaveDetails,
}) {
  const {
    status_name,
    start_date,
    end_date,
    reason,
    number_of_days,
    leave_category_name,
    having_cancel_request,
    new_start_date,
    new_end_date,
    new_number_of_days,
  } = leaveDetails;
  const {
    date_th: start_date_th,
    shortMonthName: start_month,
    year: start_year,
  } = dateInDetail(start_date);
  const {
    date_th: end_date_th,
    shortMonthName: end_month,
    year: end_year,
  } = dateInDetail(end_date);

  const {
    date_th: new_start_date_th,
    shortMonthName: new_start_month,
    year: new_start_year,
  } = dateInDetail(new_start_date);
  const {
    date_th: new_end_date_th,
    shortMonthName: new_end_month,
    year: new_end_year,
  } = dateInDetail(new_end_date);
  return (
    <StyledModal
      title="Leave Details"
      visible={isModalVisible}
      onCancel={() => closeModal(false)}
      width={400}
      footer={[]}
    >
      <StyledLeaveDetailsPopover>
        <Row gutter={10}>
          <Col span={9} className="sub_title">From date <span>:</span></Col>
          <Col span={15}>
            {start_date_th} {start_month} {start_year}
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={9} className="sub_title">To date <span>:</span></Col>
          <Col span={15}>
            {end_date_th} {end_month} {end_year}
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={9} className="sub_title">Number of days <span>:</span></Col>
          <Col span={15}>{number_of_days}</Col>
        </Row>

        {having_cancel_request === 1 && (
          <>
            <Row gutter={10}>
              <Col span={24}>
                <StyledDivider />
                <StyledAmmendmentTitle>
                  Ammendment Details
                </StyledAmmendmentTitle>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={9} className="sub_title">From date <span>:</span></Col>
              <Col span={15}>
                {new_start_date_th} {new_start_month} {new_start_year}
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={9} className="sub_title">To date <span>:</span></Col>
              <Col span={15}>
                {new_end_date_th} {new_end_month} {new_end_year}
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={9} className="sub_title">Number of days <span>:</span></Col>
              <Col span={15}>{new_number_of_days}</Col>
            </Row>

            <Row>
              <Col span={24}>
                <StyledDivider />
              </Col>
            </Row>
          </>
        )}

        <Row gutter={10}>
          <Col span={9} className="sub_title">Status <span>:</span></Col>
          <Col span={15}>{status_name}</Col>
        </Row>

        <Row gutter={10}>
          <Col span={9} className="sub_title">Leave Type <span>:</span></Col>
          <Col span={15}>{leave_category_name}</Col>
        </Row>

        <Row gutter={10}>
          <Col span={9} className="sub_title">Description <span>:</span></Col>
          <Col span={15}>
            {" "}
            <ViewMore value={reason} />
          </Col>
        </Row>
      </StyledLeaveDetailsPopover>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  leaveCategoryList: getLeaveCategoryList(state),
  userInfo: getUserInfo(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps)(ViewLeaveInfo);
