import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Table, Spin } from "antd";
// Redux
import { connect } from "react-redux";
import { getUserRoleId } from "redux/selectors";

import { CALL_API } from "common/API";
import { map } from "lodash";
import COLORS from "common/Colors";

const StyledTitle = styled.div`
  /* margin-top: 2rem; */
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  margin-bottom: 25px;
  background-color: #F0F2F5;
  padding: 10px;
`;

const StyledTable = styled(Table)`
  
  min-height: 260px; 

  table {
   
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .ant-table-tbody tr:hover > td {
   background-color: inherit;
  }
  tbody.ant-table-tbody > tr:nth-child(even) {
    background: #f9f9f9;
  }
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .highlight {
    background-color: #7095E9 !important;
    color : ${COLORS.WHITE}
  }
  
`;

const currentDate = new Date();
const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const currentDay = dayNames[currentDate.getDay()];
const columns = [
  {
    title: "Days",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "Check In Time",
    dataIndex: "shiftStartTime",
    key: "shiftStartTime",
  },
  {
    title: "Check Out Time",
    dataIndex: "shiftEndTime",
    key: "shiftEndTime",
  },
];

function WeeklyShiftDetails({ userRoleId }) {
  const [displayPendingApprovalLoader, togglePendingApprovalLoader] =
    useState(false);
  const [shiftList, setShiftList] = useState([]);

  const getShiftList = async () => {
    togglePendingApprovalLoader(true);
    const { weeklyShift = {} } = await CALL_API("weekly-shift", "post", {
      selected_role_id: userRoleId,
    });
    const result = map(weeklyShift, (value, day) => ({
      day,
      shiftStartTime: value.shiftStartTime,
      shiftEndTime: value.shiftEndTime,
    }));
    setShiftList(result);
    togglePendingApprovalLoader(false);
  };

  useEffect(() => {
    getShiftList();
  }, []);

  return (
    <div>
      <StyledTitle>Shift Details</StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayPendingApprovalLoader}>
          <StyledTable
            dataSource={shiftList}
            columns={columns}
            rowClassName={(record, index) =>
              currentDay == record.day ? "highlight" : ""
            }
            rowKey="day"
            pagination={false}
            
          />
        </Spin>
      </StyledChartContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(WeeklyShiftDetails);
