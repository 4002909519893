import React from "react";
import { Modal, Row, Col, Divider } from "antd";
// Redux
import { connect } from "react-redux";
import {
  getLeaveCategoryList,
  getUserInfo,
  getUserRoleId,
  getUserList,
} from "redux/selectors";
import styled from "@emotion/styled";
import { dateInDetail } from "utils/Date";
import ViewMore from "organisms/ViewMore";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 5px;
  display: none;
`;

const StyledRow = styled(Row)`
  padding-left: 1rem;
  margin: 5px;
`;

const StyledAmmendmentTitle = styled.div`
  padding: 10px 0px 7px 0px;
`;

const StyledLeaveDetailsPopover = styled.div`
  padding: 1rem;

  .ant-col {
    padding: 3px;
  }
  .sub_title {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
`;

function ViewTaskInfo({ isModalVisible, closeModal, taskDetails, userList }) {
  const {
    task_id = "",
    title = "",
    start_date = "",
    end_date = "",
    description = "",
    department_name = "",
    user_name = "",
    assigned_by_user_id = "",
    status_id = 0,
  } = taskDetails;

  const {
    date_th: start_date_th,
    shortMonthName: start_month,
    year: start_year,
  } = dateInDetail(start_date);
  const {
    date_th: end_date_th,
    shortMonthName: end_month,
    year: end_year,
  } = dateInDetail(end_date);

  const user = userList.find((user) => user.user_id == assigned_by_user_id);
  const assignedBy = user?.name;

  return (
    <StyledModal
      title={`Title : ${title}`}
      visible={isModalVisible}
      onCancel={() => closeModal(false)}
      width={800}
      footer={[]}
    >
      <StyledLeaveDetailsPopover>
        <StyledRow gutter={10}>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Task No <span>:</span>
              </Col>
              <Col span={15}>{`TRTP-${task_id}`}</Col>
            </StyledRow>
          </Col>
        </StyledRow>

        <StyledRow gutter={10}>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Created Date <span>:</span>
              </Col>
              <Col span={15}>
                {start_date_th} {start_month} {start_year}
              </Col>
            </StyledRow>
          </Col>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Due Date <span>:</span>
              </Col>
              <Col span={15}>
                {end_date_th} {end_month} {end_year}
              </Col>
            </StyledRow>
          </Col>
        </StyledRow>

        <StyledRow gutter={10}>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Department <span>:</span>
              </Col>
              <Col span={15}>{department_name}</Col>
            </StyledRow>
          </Col>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Assigned By <span>:</span>
              </Col>
              <Col span={15}>{assignedBy}</Col>
            </StyledRow>
          </Col>
        </StyledRow>

        <StyledRow gutter={10}>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Assigned To <span>:</span>
              </Col>
              <Col span={15}>{user_name}</Col>
            </StyledRow>
          </Col>
          <Col lg={12} md={24}>
            <StyledRow gutter={10}>
              <Col span={9} className="sub_title">
                Status <span>:</span>
              </Col>
              <Col span={15}>{status_id == 1 ? "New Task" : "Completed"}</Col>
            </StyledRow>
          </Col>
        </StyledRow>
        <StyledRow gutter={10}>
          <Col lg={24} md={24}>
            <StyledRow gutter={10}>
              <Col span={4} className="sub_title">
                Description <span>:</span>
              </Col>
              <Col span={24}>
                <StyledDivider /> <ViewMore value={description} count={200} />
              </Col>
            </StyledRow>
          </Col>
        </StyledRow>
      </StyledLeaveDetailsPopover>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  leaveCategoryList: getLeaveCategoryList(state),
  userInfo: getUserInfo(state),
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
});

export default connect(mapStateToProps)(ViewTaskInfo);
